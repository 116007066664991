import { Application } from '@hotwired/stimulus';
import { registerControllers } from 'stimulus-vite-helpers';

// Função para simular globEager usando glob
function simulateGlobEager() {
  const modules = import.meta.glob('./**/*_controller.js');
  const eagerModules = {};

  return Promise.all(
    Object.entries(modules).map(([path, module]) => {
      return module().then(controller => {
        eagerModules[path] = controller;
      });
    })
  ).then(() => eagerModules);
}

// Start the Stimulus application.
const application = Application.start();

// Controller files must be named *_controller.js.
simulateGlobEager().then(controllers => {
  registerControllers(application, controllers);
});
